import { encryptDataWithKey } from "../utils/common"

class AeraAvailaColor {
  id: string = ''
  colorName: string = ''
  colorPath: string = ''
  alt: string = ''
  bikeVideo: string = ''
  bikeImage: string = ''
  thumbnailBikeImg: string = ''
  bikeVideoMP4: string = ''
  backgroundColor?: string = ''
  colorDescription: string = ''
  bikeDirectoryName: string = ''
}
class AeraDetail {
  range: number = 0
  power: string = ''
  batteryCapacity = ''
  description: string = ''
  productName: string = ''
  productPrice: string = ''
  acceleration: string = ''
  emiAmount: string = '0'
  prebookAmount: string = '0'
  emiStartPrice: string = ''
  bikeTotalPrice: string = ''
  bikeModalImage: string = ''
  discountApplied: string = ''
  productId: string = AERA_TYPE.FIVE_THOUSHAND
  availableColors: Array<AeraAvailaColor> = []
}

enum PREBOOKING_STEP {
  LOGIN = 1,
  SUMMARY = 6,
  PAYMENT = 6,
  SIGN_UP = 3,
  CONFIGURE = 5,
  OTP_VERIFICATION = 2,
  ACTIVITE_ACCOUNT = 4,
}

enum AERA_TYPE {
  FIVE_THOUSHAND = '1',
  FIVE_THOUSHAND_PLUS = '2',
}

enum BILLDESK_PAYMENT_STATUS {
  FAILED = '0399',
  SUCCESS = '0300',
  PENDING = '0002',
}

class CreatePrebooking extends AeraDetail {
  selectedCity: string = ''
  selectedState: string = ''
  selectedColorId: string = ''
  selectedBikeModelName: string = ''
}

class OrderLink {
  href: string = ''
  rel: string = ''
  method: string = ''
  parameters?: any = null
  valid_date?: any = null
  headers?: { authorization: string } | null = null
}
class CreateOrderDetails {
  ru: string = ''
  status: string = ''
  amount: string = ''
  mercid: string = ''
  orderid: string = ''
  currency: string = ''
  objectid: string = ''
  itemcode: string = ''
  bdorderid: string = ''
  createdon: string = ''
  next_step: string = ''
  order_date: string = ''
  additional_info: any = {}
  transactionId?: string = ''
  links: Array<OrderLink> = []
}

class TransactionResponse {
  ru: string = ''
  bankid: string = ''
  mercid: string = ''
  orderid: string = ''
  amount: string = '0.00'
  discount: string = '0.00'
  surcharge: string = '0.00'
  mode: string = ''
  transactionid: string = ''
  transaction_date: string = ''
  txn_process_type: string = 'nb'
  payment_method_type: string = ''
  transaction_error_type: string = ''
  additional_info: any = {
    additional_info7: 'NA',
    additional_info6: 'NA',
    additional_info9: 'NA',
    additional_info8: 'NA',
    additional_info1: 'NA',
    additional_info3: 'NA',
    additional_info2: 'NA',
    additional_info5: 'NA',
    additional_info4: 'NA',
    additional_info10: 'NA',
  }
  itemcode: string = ''
  currency: string = ''
  objectid: string = ''
  auth_status: string = ''
  charge_amount: string = '0.00'
  transaction_error_code: string = ''
  transaction_error_desc: string = ''
}

class ExecutiveAgent {
  name: string = ''
  phone: string = ''
  email: string = ''
  executiveId: string = ''
}
class AeraDetails {
  isLoading: boolean = false
  validatePrebooking: any = {}
  availableAeras: Array<AeraDetail> = []
  availablePromoCodes: Array<PromoCode> = []
  prebookStep: number = PREBOOKING_STEP.CONFIGURE
  createPrebookingAera: CreatePrebooking = new CreatePrebooking()
  createOrderDetails: CreateOrderDetails = new CreateOrderDetails()
  transactionResponse: TransactionResponse = new TransactionResponse()
  executiveAgentList: Array<ExecutiveAgent> = []
}

class OnboardingDetails {
  tab: number = 1
  phone: string = ''
  resend: boolean = true
  resendTime: number = 0
  loaderStatus: boolean = false
  numberOtpVerified: boolean = false
  otpAttemps: number = 3
  backFromActivate: boolean = false
  checkFiveMinutes: boolean = false
}

class UserDetails {
  dob?: null
  address?: null
  whatsAppNo?: null
  imageBase64?: null
  email: string = ''
  phone: string = ''
  userToken: string = ''
  lastName: string = ''
  userName: string = ''
  firstName: string = ''
  affiliationStore?: null
  affiliationDate: string = ''
  emailVerified: boolean = false
  whatsAppVerified: boolean = false
  receiveNotifications: boolean = false
  orderList: Array<OrderDetails> = []
  profilePercentage: number = 0
  loaderStatus: boolean = false
}

class CurrentUserDetails {
  currentUserDetails: any = '';
  orderList: Array<OrderDetails> = []
}
enum LOGIN_TYPE {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  NEW_USER = 'NEW_USER',
  OLD_USER = 'OLD_USER',
}

class Subcription {
  charger: string = ''
  matterCare: string = ''
  connectivity: string = ''
}
class PriceBreakUp {
  taxes: string = ''
  bikePrice: string = ''
  itemTotal: string = ''
  total: string = ''
  subscription: Subcription = new Subcription()
  bikeOriginalPrice: string = ''
  discountApplied: string = ''
  bikeTotalPrice: string = ''
}
class LocationDetail {
  city: string = ''
  state: string = ''
  bikeColor: string = ''
  bikeModelName: string = ''
  priceBreakUp: PriceBreakUp = new PriceBreakUp()
}
class OrderDetails extends LocationDetail {
  city: string = ''
  state: string = ''
  orderId: string = ''
  bikeColor: string = ''
  orderStatus: string = ''
  createdDate: string = ''
  updatedDate: string = ''
  transactionId: string = ''
  bikeModelName: string = ''
  modifiable: boolean = true
  promoCodeAmount: string = ''
  timeStamp: string | null = null
  preBookAmount: string | null = null
  amountAfterPromoCode: string | null = null
  priceBreakUp: PriceBreakUp = new PriceBreakUp()
  payResponse: TransactionResponse = new TransactionResponse()
}

class CancelReasonContent {
  content: string = ''
}
class CancelReason {
  category: CancelReasonContent = new CancelReasonContent()
  subcategories: Array<CancelReasonContent> = []
}

class FAQCategory {
  id: number = 0
  categoryTitle: string = ''
}
class FaqMasterCategory extends FAQCategory {
  child_category_faqs: Array<FAQCategory> = []
}

class QuestionAnswerDetail {
  id: number = -1
  count: number = 0
  answer: string = ''
  question: string = ''
  isTrending: boolean = false
  subChildCategoryId?: number = -1
}
class FAQDetails {
  id: number = -1
  categoryTitle: string = ''
  faq_matersCount?: number = 0
  faq_maters: Array<QuestionAnswerDetail> = []
  master_category_faq: FAQCategory = new FAQCategory()
}
class InnovationHubFeatures {
  id: number = 0
  title: string = ''
  description: string = ''
}

class TechStackHub {
  id: number = 0
  title: string = ''
  imageUrl: string = ''
  description: string = ''
}
class InnovationHubDetails {
  videoUrl: string = ''
  publishedAt: string = ''
  totalTradeMark: number = 0
  techStackTitle: string = ''
  totalPatentFiled: number = 0
  intellactualTitle: string = ''
  techStackDescription: string = ''
  totalDesignRegistered: number = 0
  intellactualDescription: string = ''
  techStackList: Array<TechStackHub> = []
}

class WhoWeAreRewardDetail {
  id: number = 0
  rewardTitle: string = ''
  rewardMedia: string = ''
  rewardDescription: string = ''
}
class WhoWeAreRewards {
  title: string = ''
  rewardList: Array<WhoWeAreRewardDetail> = []
}

class OurStoryEvent {
  id: number = 0
  eventTitle: string = ''
  eventYear: string = ''
  eventDescription: string = ''
  eventMedia: string = ''
}

class WhoWeAreEvents {
  title: string = ''
  description: string = ''
  eventsList: Array<OurStoryEvent> = []
}

class WhoWeAreLeader {
  id: number = 1
  name: string = ''
  designation: string = ''
  profileImage: string = ''
  linkedInProfile: string = ''
  profileImageMobile: string = ''
}
class WhoWeAreLeaders {
  title: string = ''
  description: string = ''
  leadersList: Array<WhoWeAreLeader> = []
}

class WhoWeAre {
  title: string = ''
  impactGoalTitle2: string = ''
  impactGoalTitle1: string = ''
  missionVisionAppImage: string = ''
  missionVisionWebImage: string = ''
  impactGoalDescription1: string = ''
  impactGoalDescription2: string = ''
  rewards: WhoWeAreRewards = new WhoWeAreRewards()
  leaders: WhoWeAreLeaders = new WhoWeAreLeaders()
  ourStoryEvents: WhoWeAreEvents = new WhoWeAreEvents()
}

class BlogsCategory {
  id: number = 0
  categoryName: string = ''
  allblogs: Array<Blogs> = []  
}

class Blogs {
  id: number = 0
  title: string = ''
  publishDate: string = ''
  readTime: number = 0
  matterBlogImageWeb: string = ''
  matterBlogImageApp: string = ''
  matterBlogThumbNailImageWeb: string = ''
  matterBlogThumbNailImageApp: string = ''
  blogImageDescription: string = ''
  blogIntroduction: string = ''
  blogStream1: string = ''
  blogExtraPictures: [] = []
  extraPicturesDescription: string = ''
  blogStream2: string = ''
}

class JoinWaitlistTC {
  title: string = ''
  introduction: string = ''
  listContent : Array<{title : string, description : ''}> = []
}

class OnlinePreBookTC {
  title: string = ''
  introduction: string = ''
  listContent : Array<{title : string, description : ''}> = []
}



// class HomeDetails {
//   exploreMatterTitle: string= ''
//   channelPartnerTitle: string = ''
//   channelPartnerDescription: string = ''
// }
class Home {
  availableLocationList: Array<LocationDetail> = []
  cancelReasons: Array<CancelReason> = []
  topHomeBanner: { desktopmessage: string; mobilemessage: string } = {
    desktopmessage: '',
    mobilemessage: '',
  }

  HomeDetails: { sec1heading: string; sec1learnmore: string; sec1CTA: string; sec2Heading: string;  sec2Img:string; sec2Img2:string; sec2CTA: string; sec3Redefine:string;
                 sec3Reimagine: string; partnerHeading:string; partnerOTO: string ; partnerWithUs: string; partnerWithUsContent: string;
                 partnerWithUsCTA: string; exploreHeading: string;  footerText: string;  Interest: string; InterestCTA: string; partnerSubtext: string; footerCTA: string;
                 footerCopyrights: string;  partnerImg1: string;  partnerImg4: string; partnerImg2:string; partnerUsImg:string; partnerFlipkart:string; 
                 footerInsta:string; footerLinkedin:string; footerYoutube:string; footerX:string; footerFacebook: string; Launches:string;
                 LaunchesContent:string; partnerImg1Pre: string;
 
  } = {         
    sec1heading: '', sec1learnmore: '',sec1CTA:'', sec2Heading:'',  sec2Img:'', sec2Img2:'', sec2CTA:'', sec3Redefine:'', sec3Reimagine:'', partnerHeading:'',
    partnerOTO: '', partnerWithUs: '', partnerWithUsContent:'', partnerWithUsCTA:'', exploreHeading:'', footerText:'',  Interest:'',InterestCTA: '',
    partnerSubtext:'', footerCTA:'', footerCopyrights:'',  partnerImg1:'', partnerImg4:'', partnerImg2:'',partnerUsImg:'', partnerFlipkart:'',
    footerInsta:'', footerLinkedin:'', footerYoutube:'', footerX:'', footerFacebook:'',Launches:'',LaunchesContent:'', partnerImg1Pre:'',

  }

  
  contactUs: { callUs: string; email: string; whatsApp: string } = {
    callUs: '',
    email: '',
    whatsApp: '',
  }
  // homeDetails:HomeDetails = new HomeDetails()
  faqCategories: Array<FaqMasterCategory> = []
  faqDetail: FAQDetails = new FAQDetails()
  trendingFAQ: Array<QuestionAnswerDetail> = []
  faqAppliedSearch: FAQDetails = new FAQDetails()
  innovationHub: InnovationHubDetails = new InnovationHubDetails()
  whoWeAre: WhoWeAre = new WhoWeAre()
  joinWaitListkey : boolean = true
  appConfig: {joinWaitListkey:boolean } = {
      joinWaitListkey: true
    
  }
  blogDetails: Blogs =  new Blogs()
  blogsCategories: Array<BlogsCategory> = []
  joinWaitlistTC: JoinWaitlistTC = new JoinWaitlistTC()
  OnlinePreBookTC: OnlinePreBookTC = new OnlinePreBookTC()
}

class PromoCode {
  couponCode: string = ''
  createTime: string = ''
  updateTime: string = ''
  couponType: string = ''
  expired: boolean = false
  couponAmount: string = ''
  validityType: string = ''
  validityValue: string = ''
}

enum BIKE_MODEL_NAME {
  AERA_5000 = 'Aera 5000',
  AERA_5000_PLUE = 'Aera 5000+',
}
enum ORDER_STATUS {
  CANCELED = 'CANCELLED',
  IN_PROGRESS = 'IN-PROGRESS',
  MODIFIED = 'MODIFIED',
  SUCCESS = 'SUCCESS',
}

class RegisterYourInterest extends UserDetails {
  phone: string = ''
  otpVerifed: boolean = false
  verifiedPhoneNumber: string = ''
}

class ChannelPartnerFromData {
  city: string = ''
  state: string = ''
  phone: string = ''
  email: string = ''
  firstName: string = ''
  lastName: string = ''
  firmName: string = ''
}

class ChannelPartnerQuestion {
  charLimit: number = 0
  id: number = -1
  publishedAt: string = ''
  question: string = ''
  answer?: string = ''
}
class PartnerStage {
  id: number = 0
  publishedAt: string = ''
  partnerStage: number = -1
  categoryTitle: string = ''
  channel_partner_questions: Array<ChannelPartnerQuestion> = []
}

class partnerWirhUs {
  title: string = ''
  subTitle: string = ''
  pageDescription: string = ''
  publishedAt: string = ''
}

class PartnerAndChannel {
  phone: string = ''
  type: string = ''
  activeStep: number = 0
  isChannelPartnerInitiated: boolean = false
  partnerWithUs: partnerWirhUs = new partnerWirhUs()
  countries: any = []
  ChannelPartnerFromData: string = encryptDataWithKey(new ChannelPartnerFromData(),'')
  channelPartnerQuestions: Array<PartnerStage> = []
}

export class DealerDetails {
  distance : string = ''
  contactNo : string = ''
  dealerName: string = ''
  dealerAddress : string = ''
  lat : number = 0;
  lng : number = 0
  id : number = 0

}
class DealerLocator  {
  searchResult: any =[]
  allDealersList: Array<DealerDetails> = []
}

class Scheduling {
   
}

class LocatorAndScheduling {
  dealerLocator:DealerLocator = new DealerLocator()
  scheduling: Scheduling = new Scheduling()
}

export {
  Home,
  PromoCode,
  AERA_TYPE,
  AeraDetail,
  LOGIN_TYPE,
  AeraDetails,
  UserDetails,
  FAQDetails,
  FAQCategory,
  ORDER_STATUS,
  OrderDetails,
  LocationDetail,
  PREBOOKING_STEP,
  BIKE_MODEL_NAME,
  CreatePrebooking,
  FaqMasterCategory,
  OnboardingDetails,
  CreateOrderDetails,
  BILLDESK_PAYMENT_STATUS,
  RegisterYourInterest,
  PartnerAndChannel,
  PartnerStage,
  ChannelPartnerFromData,
  InnovationHubDetails,
  WhoWeAreRewards,
  WhoWeAreEvents,
  OurStoryEvent,
  WhoWeAreLeaders,
  Blogs,
  BlogsCategory,
  LocatorAndScheduling,
  CurrentUserDetails
}
