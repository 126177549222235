import Api from '../../api'
import { ActionTypes } from '../../constants'
import { endpoints } from '../../api/endPoints'
import { API_SUCCESS_CODE } from '../../api/constant'
import {
  BIKE_MODEL_NAME,
  FAQCategory,
  FAQDetails,
  InnovationHubDetails,
  WhoWeAreEvents,
  WhoWeAreLeaders,
  WhoWeAreRewards,
  Blogs,
  BlogsCategory,
} from '../../constants/model'
import PrebookingActions from '../prebookingActions'
import moment from 'moment'
import { ReducerModel } from '../../reducers/reducerModel'

const formatPriceBikes = (availableLoc: Array<any>) => {
  const loc: any = {}

  availableLoc.forEach((item, index) => {
    if (item.priceBreakUp) {
      item.priceBreakUp.productPrice =
        item.priceBreakUp.bikeOriginalPrice || '0'
      const bikeDetails = {
        bikeModelName: item.bikeModelName,
        priceBreakUp: item.priceBreakUp,
      }
      if (!loc[item.city + item.state]) {
        loc[item.city + item.state] = {
          state: item.state,
          city: item.city,
          availableBikes: [bikeDetails],
        }
      } else {
        if (
          !loc[item.city + item.state].availableBikes.find(
            (subItem: any) => subItem.bikeModelName == item.bikeModelName
          )
        ) {
          loc[item.city + item.state].availableBikes.push(bikeDetails)
        }
      }
    }
  })

  const newData = []
  for (const key in loc) {
    newData.push(loc[key])
  }

  return newData.filter((item) => item.availableBikes.length)
}

const getLocationList = (success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    Api.getApiCall(
      endpoints.product.locationListings,
      '',
      (response: any) => {
        if (response) {
          const { error, responseCode, data, message } = response
          if (!error && responseCode == API_SUCCESS_CODE.success) {
            const validLocations =
              (data &&
                data.filter(
                  (item: any) =>
                    item.bikeModelName === BIKE_MODEL_NAME.AERA_5000 ||
                    item.bikeModelName === BIKE_MODEL_NAME.AERA_5000_PLUE
                )) ||
              []

            const formatedLoc = formatPriceBikes(validLocations)

            formatedLoc.map((item) => {
              const fiveThousandData = item.availableBikes.filter(
                (bikeItem: any) =>
                  bikeItem.bikeModelName == BIKE_MODEL_NAME.AERA_5000
              )
              const fiveThousandPlusData = item.availableBikes.filter(
                (bikeItem: any) =>
                  bikeItem.bikeModelName == BIKE_MODEL_NAME.AERA_5000_PLUE
              )

              item.availableBikes = [
                ...fiveThousandData,
                ...fiveThousandPlusData,
              ]
            })
            dispatch({
              type: ActionTypes.UPDATE_AVAILABLE_LOCATIONS_LIST,
              payload: { availableLocationList: formatedLoc || [] },
            })
            success(formatedLoc)
            return
          }
          fail({ message })
          return
        }
        fail()
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.UPDATE_AVAILABLE_LOCATIONS_LIST,
          payload: { availableLocationList: [] },
        })
        dispatch(PrebookingActions.resetCreatePreBooking())
        fail(error)
      }
    )
  }
}

const getModifyLocationList = (
  orderId: string,
  success: Function,
  fail: Function
) => {
  return (dispatch: Function, getState: Function) => {
    Api.getApiCall(
      endpoints.product.locationListings + `?orderId=${orderId}`,
      '',
      (response: any) => {
        if (response) {
          const { error, responseCode, data, message } = response
          if (!error && responseCode == API_SUCCESS_CODE.success) {
            const validLocations =
              (data &&
                data.filter(
                  (item: any) =>
                    item.bikeModelName === BIKE_MODEL_NAME.AERA_5000 ||
                    item.bikeModelName === BIKE_MODEL_NAME.AERA_5000_PLUE
                )) ||
              []

            const formatedLoc = formatPriceBikes(validLocations)

            formatedLoc.map((item) => {
              const fiveThousandData = item.availableBikes.filter(
                (bikeItem: any) =>
                  bikeItem.bikeModelName == BIKE_MODEL_NAME.AERA_5000
              )
              const fiveThousandPlusData = item.availableBikes.filter(
                (bikeItem: any) =>
                  bikeItem.bikeModelName == BIKE_MODEL_NAME.AERA_5000_PLUE
              )

              item.availableBikes = [
                ...fiveThousandData,
                ...fiveThousandPlusData,
              ]
            })
            dispatch({
              type: ActionTypes.UPDATE_AVAILABLE_LOCATIONS_LIST,
              payload: { availableLocationList: formatedLoc || [] },
            })
            success(formatedLoc)
            return
          }
          fail({ message })
          return
        }
        fail()
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.UPDATE_AVAILABLE_LOCATIONS_LIST,
          payload: { availableLocationList: [] },
        })
        dispatch(PrebookingActions.resetCreatePreBooking())
        fail(error)
      }
    )
  }
}

const getCancelReasons = () => {
  return (dispatch: Function, getState: Function) => {
    Api.getApiCall(
      endpoints.product.cancelReason,
      '',
      (response: any) => {
        const { responseCode, data, error, message } = response
        if (data && !error && responseCode == API_SUCCESS_CODE.success) {
          dispatch({
            type: ActionTypes.CANCEL_REASONS,
            payload: { cancelReasons: data || [] },
          })
        } else {
          dispatch({
            type: ActionTypes.CANCEL_REASONS,
            payload: { cancelReasons: [] },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.CANCEL_REASONS,
          payload: { cancelReasons: [] },
        })
      }
    )
  }
}

// Strapi Banner api
// GET banner data

const getBannerStrapiApi = () => {
  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.topBanner,
      '',
      (response: any) => {
        if (response) {
          const { data } = response
          dispatch({
            type: ActionTypes.TOP_BANNER,
            payload: {
              topHomeBanner: {
                desktopmessage: data?.attributes?.desktopmessage || '',
                mobilemessage: data?.attributes?.mobilemessage || '',
              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.TOP_BANNER,
            payload: {
              topHomeBanner: {
                desktopmessage: '',
                mobilemessage: '',
              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.TOP_BANNER,
          payload: {
            topHomeBanner: {
              desktopmessage: '',
              mobilemessage: '',
            },
          },
        })
      }
    )
  }
}

//Contact Us Get Strapi api

const getContactUsStrapiApi = () => {
  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.contactUs,
      '',
      (apiRespone: any) => {
        if (apiRespone) {
          const { data } = apiRespone
          dispatch({
            type: ActionTypes.CONTACT_US,
            payload: {
              contactUs: {
                email: data?.attributes?.email || '',
                callUs: data?.attributes?.callUs || '',
                whatsApp: data?.attributes?.whatsApp || '',
              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.CONTACT_US,
            payload: {
              contactUs: {
                email: '',
                callUs: '',
                whatsApp: '',
              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.CONTACT_US,
          payload: {
            contactUs: {
              email: '',
              callUs: '',
              whatsApp: '',
            },
          },
        })
      }
    )
  }
}

// const getHomePageDetails = () => {
//   let dataaa = {
//     data: {
//       id: 1,
//       attributes: {
//         exploreMatterTitle:
//           'An electric motorbike that not only shifts gears but also paradigms.',
//         channelPartnerTitle: 'Partner with us',
//         channelPartnerDescription:
//           "If you believe if providing exceptional experiences, and  buy into the idea of an electric future, let's explore synergies!",
//         createdAt: '2024-02-07T04:16:00.962Z',
//         updatedAt: '2024-02-07T04:17:14.673Z',
//         publishedAt: '2024-02-07T04:17:14.671Z',
//       },
//     },
//     meta: {},
//   }

//   const {
//     data: {
//       attributes: {
//         exploreMatterTitle,
//         channelPartnerTitle,
//         channelPartnerDescription,
//       },
//     },
//   } = dataaa

//   return (dispatch: Function, getState: Function) => {
//     dispatch({
//       type: ActionTypes.HOME_PAGE_DETAILS,
//       payload: {
//         homeDetails: {
//           exploreMatterTitle,
//           channelPartnerTitle,
//           channelPartnerDescription,
//         },
//       },
//     })

// Api.getStrapiApiCall(
    //   endpoints.strapi.homePage,
    //   '',
    //   (apiRespone: any) => {
    //     if (apiRespone) {
    //       const { data } = apiRespone
    //       console.log('getHomePageDetails', apiRespone)
    //       // dispatch({
    //       //   type: ActionTypes.HOME_PAGE_DETAILS,
    //       //   payload: {},
    //       // })
    //     } else {
    //       // dispatch({
    //       //   type: ActionTypes.HOME_PAGE_DETAILS,
    //       //   payload: {},
    //       // })
    //     }
    //   },
    //   (error: any) => {
    //     // dispatch({
    //     //   type: ActionTypes.HOME_PAGE_DETAILS,
    //     //   payload: {},
    //     // })
    //   }
    // )
//   }
// }

const getHomePageDetails = () => {
  return (dispatch: Function, getState: Function) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.homePage
      : endpoints.strapi.homePage

    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (response: any) => {
        if (response ) {
          
          const { data } = response
          dispatch({
            type: ActionTypes.HOME_PAGE_DETAILS,
            payload: {
              HomeDetails: {
                sec1heading: data?.attributes?.section_1_heading|| '',
                sec1learnmore: data?.attributes?.section_1_learn_more || '',
                sec1CTA: data?.attributes?.section_1_cta || '',
                sec2Heading: data?.attributes?.section_2_heading ||'',
                sec2Img:data?.attributes?.section_2_img?.data.attributes?.formats?.large?.url  ||'',
                sec2Img2:data?.attributes?.section_2_img_2?.data.attributes?.url  ||'',
                sec2CTA:data?.attributes?.section_2_cta ||'',
                sec3Redefine:data?.attributes?.section_3_redifining ||'',
                sec3Reimagine:data?.attributes?.section_3_reimagining ||'',
                partnerHeading:data?.attributes?.partner_channel_heading ||'',
                partnerOTO: data?.attributes?.partner_channel_oto?.data?.attributes?.url || '',
                partnerWithUs:data?.attributes?.partner_with_us_heading ||'',
                partnerWithUsContent:data?.attributes?.partner_with_us_content ||'',
                partnerWithUsCTA:data?.attributes?.partner_with_us_cta ||'',
                exploreHeading:data?.attributes?.explore_the_era_heading ||'',
                footerText:data?.attributes?.footer_heading ||'',
                Interest: data?.attributes?.interest ||'',
                InterestCTA:data?.attributes?.interest_cta ||'',
                partnerSubtext:data?.attributes?.partner_channel_subtext ||'',
                footerCTA:data?.attributes?.footer_cta ||'',
                footerCopyrights:data?.attributes?.footer_copyrights_text ||'',
                partnerImg1:data?.attributes?.partner_channel_img1?.data?.attributes?.formats?.large?.url ||'',
                partnerImg1Pre:data?.attributes?.partner_channel_img1_pre?.data?.attributes?.formats?.small?.url ||'',
                partnerImg4:data?.attributes?.partner_channel_img4?.data?.attributes?.url ||'',
                partnerImg2: data?.attributes?.partner_channel_img2?.data?.attributes?.url ||'',
                partnerUsImg: data.attributes.partner_with_us_img.data.attributes.url ||'',
                partnerFlipkart: data?.attributes?.partner_channel_flipkart?.data?.attributes?.url || '',
                footerInsta:data?.attributes?.footer_instagram?.data?.attributes.url ||'',
                footerLinkedin:data?.attributes?.footer_linkedin?.data?.attributes.url ||'',
                footerYoutube:data?.attributes?.footer_youtube?.data?.attributes.url||'',
                footerX:data?.attributes?.footer_X?.data?.attributes.url ||'',
                footerFacebook:data?.attributes?.footer_facebook?.data?.attributes.url ||'',
                Launches:data?.attributes?.upcoming_launches ||'',
                LaunchesContent:data?.attributes?.upcoming_launches_content ||'',
                top_banner_image: data?.attributes?.top_banner_image?.data?.attributes?.url,
                top_banner_image_mob: data?.attributes?.top_banner_image_mob?.data?.attributes?.url,
              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.HOME_PAGE_DETAILS,
            payload: {
              HomeDetails: {
                sec1heading: '',sec1learnmore: '',sec1CTA:'',sec2Heading:'',sec2Img:'',sec2Img2:'',sec2CTA:'',sec3Redefine:'',
                sec3Reimagine:'',partnerHeading:'',partnerOTO: '',partnerWithUs:'',partnerWithUsContent:'',partnerWithUsCTA:'',
                exploreHeading:'',footerText:'',Interest:'',InterestCTA:'',partnerSubtext:'',footerCTA:'',footerCopyrights:'',
                partnerImg1:'',partnerImg4:'',partnerImg2:'',partnerUsImg:'',partnerFlipkart:'',footerInsta:'',footerLinkedin:'',
                footerYoutube:'',footerX:'',footerFacebook:'',Launches:'',LaunchesContent:'',partnerImg1Pre:'',
              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.HOME_PAGE_DETAILS,
          payload: {
            HomeDetails: {
              sec1heading: '',sec1learnmore: '',sec1CTA:'',sec2Heading:'',sec2Img:'',sec2Img2:'',sec2CTA:'',sec3Redefine: '',
              sec3Reimagine:'',partnerHeading:'',partnerOTO: '',partnerWithUs:'',partnerWithUsContent:'',partnerWithUsCTA:'',
              exploreHeading:'',footerText:'',Interest:'',InterestCTA:'',partnerSubtext:'',footerCTA:'',footerCopyrights:'',
              partnerImg1:'',partnerImg4:'',partnerImg2:'',partnerUsImg:'',partnerFlipkart:'',footerInsta:'',footerLinkedin:'',
              footerYoutube:'',footerX:'',footerFacebook:'',Launches:'',LaunchesContent:'',partnerImg1Pre:'',
            },
          },
        })
      }
    )
  }
}

// FAQ Categories API

const getFaqCategoriesStrapiApi = (
  successCallback: Function,
  failureCallback: Function
) => {
  return (dispatch: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.masterFAQCategory,
      '',
      (apiRespone: any) => {
        if (apiRespone) {
          const formatedMasterCategory: Array<any> =
            apiRespone?.data.map((item: any) => {
              const formatedResponse: any = {
                id: item.id,
                ...item.attributes,
              }
              formatedResponse.child_category_faqs =
                formatedResponse.child_category_faqs?.data?.map(
                  (childCategory: any) => {
                    const childFormatedResponse: any = {
                      id: childCategory.id,
                      ...childCategory.attributes,
                    }
                    return childFormatedResponse
                  }
                ) || []
              return formatedResponse
            }) || []
          dispatch({
            type: ActionTypes.FAQ_CATEGORIES,
            payload: {
              faqCategories: formatedMasterCategory,
            },
          })
          if (
            formatedMasterCategory &&
            formatedMasterCategory.length &&
            formatedMasterCategory[0].child_category_faqs.length
          ) {
            dispatch(
              getCategoryFAQs(
                formatedMasterCategory[0].child_category_faqs[0].id,
                successCallback,
                failureCallback
              )
            )
          }
        } else {
          dispatch({
            type: ActionTypes.FAQ_CATEGORIES,
            payload: {
              faqCategories: [],
            },
          })
          failureCallback()
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.FAQ_CATEGORIES,
          payload: {
            faqCategories: [],
          },
        })
        failureCallback(error)
      }
    )
  }
}

const getCategoryFAQs = (
  childCategoryid: number,
  sucessCallback: Function,
  failureCallback: Function
) => {
  return (dispatch: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.childCategoryFAQList,
      `/${childCategoryid}?populate=*`,
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          const { id, attributes } = apiRespone.data
          const formatedFAQ: FAQDetails = {
            id: id,
            categoryTitle: attributes.categoryTitle,
            master_category_faq: {
              id: attributes.master_category_faq?.data?.id || -1,
              ...attributes.master_category_faq?.data?.attributes,
            },
            faq_maters:
              attributes?.faq_maters?.data
                .map((item: any) => {
                  // console.log("item =>",item)
                  const formatedQA = item.attributes.publishedAt && {
                    id: item.id,
                    ...item.attributes,
                  }
                  return formatedQA
                })
                .filter(Boolean) || [],
          }
          dispatch({
            type: ActionTypes.FAQ_DETAILS,
            payload: {
              faqDetail: formatedFAQ,
            },
          })
          sucessCallback(formatedFAQ)
          return
        } else {
          dispatch({
            type: ActionTypes.FAQ_DETAILS,
            payload: {
              faqDetail: new FAQDetails(),
            },
          })
          failureCallback()
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.FAQ_DETAILS,
          payload: {
            faqDetail: new FAQDetails(),
          },
        })
        failureCallback(error)
      }
    )
  }
}

const getMasterChildFAQ = (
  sucessCallback: Function,
  failureCallback: Function
) => {
  return (dispatch: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.masterChildFAQ,
      `?populate=*`,
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          let trendingFAQ: Array<any> = []
          apiRespone.data.map((item: any) => {
            const trandingqa =
              item.attributes?.faq_maters?.data.map((faqItem: any) => {
                const formatedFQA = { id: faqItem.id, ...faqItem.attributes }
                return formatedFQA
              }) || []
            trendingFAQ.push(...trandingqa)
          })
          trendingFAQ.sort((a: any, b: any) => {
            //@ts-ignore
            return b.count - a.count
          })
          dispatch({
            type: ActionTypes.TRENDING_FAQ,
            payload: {
              trendingFAQ: trendingFAQ.slice(0, 5),
            },
          })

          sucessCallback(trendingFAQ)
          return
        } else {
          dispatch({
            type: ActionTypes.FAQ_DETAILS,
            payload: {
              trendingFAQ: [],
            },
          })
          failureCallback()
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.FAQ_DETAILS,
          payload: {
            trendingFAQ: [],
          },
        })
        failureCallback(error)
      }
    )
  }
}

const getFAQAppliedSearch = (
  search: string,
  sucessCallback: Function,
  failureCallback: Function
) => {
  return (dispatch: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.faqList,
      `?populate=*&filters[question][$containsi]=${search}`,
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          const {
            meta: { pagination },
          } = apiRespone
          const formatedFaq = search
            ? apiRespone.data.map((item: any) => {
                const {
                  attributes: {
                    publishedAt,
                    answer,
                    count,
                    isTrending,
                    question,
                    child_category_faq,
                  },
                  id,
                } = item
                return {
                  publishedAt,
                  answer,
                  count,
                  id,
                  isTrending,
                  question,
                  subChildCategoryId: child_category_faq?.data?.id || 0,
                }
              })
            : []
          const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)
          const faqDetail: FAQDetails = {
            id: 0,
            faq_maters: formatedFaq?.filter((item: any) => item.publishedAt),
            faq_matersCount: search ? pagination.total : 0,
            categoryTitle: `${pagination.total} Results "${capitalize(
              search
            )}"`,
            master_category_faq: new FAQCategory(),
          }
          dispatch({
            type: ActionTypes.FAQ_APPLIED_SEARCH,
            payload: {
              faqAppliedSearch: faqDetail,
            },
          })
          sucessCallback(faqDetail)
          return
        } else {
          dispatch({
            type: ActionTypes.FAQ_APPLIED_SEARCH,
            payload: {
              faqAppliedSearch: new FAQDetails(),
            },
          })
          failureCallback()
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.FAQ_APPLIED_SEARCH,
          payload: {
            faqAppliedSearch: new FAQDetails(),
          },
        })
        failureCallback(error)
      }
    )
  }
}

const resetFAQ = () => {
  return (dispatch: Function) => {
    dispatch({
      type: ActionTypes.RESET_FAQ,
      payload: {
        trendingFAQ: [],
        faqAppliedSearch: new FAQDetails(),
        faqDetail: new FAQDetails(),
      },
    })
  }
}

const updateFAQDetails = (faqDetails: FAQDetails) => {
  return (dispatch: Function) => {
    dispatch({
      type: ActionTypes.FAQ_DETAILS,
      payload: {
        faqDetail: faqDetails,
      },
    })
  }
}

const getAppConfig = () => {
  return (dispatch: Function, getState: Function) => {
    Api.getApiCall(
      endpoints.orbitConfig.config,
      '',
      (apiRespone: any) => {
        const { responseCode, data, error } = apiRespone
        if (responseCode == API_SUCCESS_CODE.success && !error && data) {
          const joinWaitListkey = data.find(
            (item: any) => item.configKey == 'join-wait-list'
          )?.configValue
          const { home }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.UPDATE_WEB_APP_CONFIG,
            payload: {
              appConfig: {
                ...home.appConfig,
                joinWaitListkey: joinWaitListkey === 'true',
              },
            },
          })
        }

        console.log('apiRespone', apiRespone)
      },
      (error: any) => {
        console.log('error', error)
      }
    )
  }
}

const getInnovationHubDetails = () => {
  return (dispatch: Function) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getInnovationTechStack
      : endpoints.strapi.getInnovationTechStack
    Api.getStrapiApiCall(
      apiEndpoint,
      `?populate=*`,
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data && apiRespone.data.length) {
          const {
            attributes: { innovation_hub },
          } = apiRespone.data[0]
          const innovationHubDetails = new InnovationHubDetails()
          if (innovation_hub.data.attributes) {
            const {
              videoUrl,
              publishedAt,
              techStackTitle,
              totalTradeMark,
              totalPatentFiled,
              intellactualTitle,
              techStackDescription,
              totalDesignRegistered,
              intellactualDescription,
            } = innovation_hub.data.attributes

            innovationHubDetails.videoUrl = videoUrl
            innovationHubDetails.publishedAt = publishedAt
            ;(innovationHubDetails.techStackTitle = techStackTitle),
              (innovationHubDetails.totalTradeMark = totalTradeMark),
              (innovationHubDetails.totalPatentFiled = totalPatentFiled),
              (innovationHubDetails.intellactualTitle = intellactualTitle),
              (innovationHubDetails.techStackDescription =
                techStackDescription),
              (innovationHubDetails.totalDesignRegistered =
                totalDesignRegistered),
              (innovationHubDetails.intellactualDescription =
                intellactualDescription)
          }
          const formatedTechStack = apiRespone.data.map((item: any) => {
            if (item.attributes.publishedAt) {
              const {
                id,
                attributes: { description, imageUrl, title },
              } = item
              const { url } = imageUrl.data.attributes
              return { id, description, imageUrl: url, title }
            }
          })
          innovationHubDetails.techStackList = formatedTechStack
          dispatch({
            type: ActionTypes.INNOVATION_HUB,
            payload: {
              innovationHub: innovationHubDetails,
            },
          })
          return
        } else {
          dispatch({
            type: ActionTypes.INNOVATION_HUB,
            payload: {
              innovationHub: new InnovationHubDetails(),
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.INNOVATION_HUB,
          payload: {
            innovationHub: new InnovationHubDetails(),
          },
        })
      }
    )
  }
}

const getWhoWeAreAchivements = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getWhoWeAreReward
      : endpoints.strapi.getWhoWeAreReward
    Api.getStrapiApiCall(
      apiEndpoint,
      `?populate=*`,
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data && apiRespone.data.length) {
          const { data } = apiRespone
          let rewardAchievmentTitle = ''
          const rewardList = data
            .map((item: any) => {
              const { id, attributes } = item
              if (attributes.publishedAt) {
                const {
                  rewardDescription,
                  rewardTitle,
                  rewardMedia,
                  who_we_are_reward_list,
                } = attributes
                if (!rewardAchievmentTitle && who_we_are_reward_list) {
                  rewardAchievmentTitle =
                    who_we_are_reward_list?.data?.attributes?.title || ''
                }
                return {
                  id,
                  rewardDescription,
                  rewardTitle,
                  rewardMedia: rewardMedia?.data?.attributes?.url || '',
                }
              }
            })
            .filter((item: any) => item)

          const rewardAndAchievment = {
            title: rewardAchievmentTitle,
            rewardList,
          }
          const {
            home: { whoWeAre },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.WHO_WE_ARE_REWARDS,
            payload: {
              whoWeAre: { ...whoWeAre, rewards: rewardAndAchievment },
            },
          })

          return
        } else {
          const {
            home: { whoWeAre },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.WHO_WE_ARE_REWARDS,
            payload: {
              whoWeAre: { ...whoWeAre, rewards: new WhoWeAreRewards() },
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { whoWeAre },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.WHO_WE_ARE_REWARDS,
          payload: {
            whoWeAre: { ...whoWeAre, rewards: new WhoWeAreRewards() },
          },
        })
      }
    )
  }
}

const getWhoWeAreEvents = (successCallback: Function) => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getWhoWeAreEvent
      : endpoints.strapi.getWhoWeAreEvent
    Api.getStrapiApiCall(
      apiEndpoint,
      `?populate=*`,
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data && apiRespone.data.length) {
          const { data } = apiRespone
          let ourStoryTitle = ''
          let ourStoryDescription = ''
          const eventList = data

            .map((item: any) => {
              const { id, attributes } = item
              if (attributes.publishedAt) {
                const {
                  eventDescription,
                  eventTitle,
                  eventYear,
                  who_we_are_story,
                  eventMediaUrl,
                } = attributes
                if (!ourStoryTitle && who_we_are_story) {
                  ourStoryTitle =
                    who_we_are_story?.data?.attributes?.title || ''
                  ourStoryDescription =
                    who_we_are_story?.data?.attributes?.description || ''
                }
                return {
                  id,
                  eventDescription,
                  eventYear,
                  eventTitle,
                  eventMedia: eventMediaUrl?.data?.attributes?.url || '',
                }
              }
            })
            .filter((item: any) => item)
            .sort(
              (a: any, b: any) => parseInt(a.eventYear) - parseInt(b.eventYear)
            )
          const ourStoryEventDetails = {
            title: ourStoryTitle,
            description: ourStoryDescription,
            eventsList: eventList,
          }
          const {
            home: { whoWeAre },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.WHO_WE_ARE_EVENTS,
            payload: {
              whoWeAre: { ...whoWeAre, ourStoryEvents: ourStoryEventDetails },
            },
          })
          successCallback && successCallback(eventList)
          return
        } else {
          const {
            home: { whoWeAre },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.WHO_WE_ARE_EVENTS,
            payload: {
              whoWeAre: { ...whoWeAre, ourStoryEvents: new WhoWeAreEvents() },
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { whoWeAre },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.WHO_WE_ARE_EVENTS,
          payload: {
            whoWeAre: { ...whoWeAre, ourStoryEvents: new WhoWeAreEvents() },
          },
        })
      }
    )
  }
}

const getImageUrlFromStrapi = (mediaObj: any) => {
  return mediaObj?.data?.attributes?.url || ''
}
const getWhoWeAreDetails = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getWHoWeAre
      : endpoints.strapi.getWHoWeAre
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data && apiRespone.data.length) {
          const { data } = apiRespone
          if (data && data.length) {
            const {
              home: { whoWeAre },
            }: ReducerModel = getState()
            const {
              title,
              missionVisionAppImage,
              missionVisionWebImage,
              impactGoalDescription1,
              impactGoalDescription2,
              impactGoalTitle1,
              impactGoalTitle2,
            } = data[0].attributes
            dispatch({
              type: ActionTypes.WHO_WE_ARE_DETAILS,
              payload: {
                whoWeAre: {
                  ...whoWeAre,
                  title,
                  impactGoalDescription1,
                  impactGoalDescription2,
                  impactGoalTitle1,
                  impactGoalTitle2,
                  missionVisionAppImage: getImageUrlFromStrapi(
                    missionVisionAppImage
                  ),
                  missionVisionWebImage: getImageUrlFromStrapi(
                    missionVisionWebImage
                  ),
                },
              },
            })
          }
          return
        } else {
          const {
            home: { whoWeAre },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.WHO_WE_ARE_DETAILS,
            payload: {
              whoWeAre: { ...whoWeAre },
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { whoWeAre },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.WHO_WE_ARE_DETAILS,
          payload: {
            whoWeAre: { ...whoWeAre },
          },
        })
      }
    )
  }
}

const getWhoWeAreLeaders = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.whoWeAreLeaders
      : endpoints.strapi.whoWeAreLeaders
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data && apiRespone.data.length) {
          const { data } = apiRespone
          if (data && data.length) {
            let leadersTitle = ''
            let leadersDescription = ''
            const {
              home: { whoWeAre },
            }: ReducerModel = getState()
            const leadersList = data
              .map((item: any) => {
                const { id, attributes } = item
                if (attributes.publishedAt) {
                  const {
                    designation,
                    linkedInProfile,
                    name,
                    profileImage,
                    placementOrder,
                    profileImageMobile,
                    who_we_are_leader,
                  } = attributes
                  if (!leadersTitle && who_we_are_leader) {
                    leadersTitle =
                      who_we_are_leader?.data?.attributes?.title || ''
                    leadersDescription =
                      who_we_are_leader?.data?.attributes?.description || ''
                  }
                  return {
                    id,
                    designation,
                    linkedInProfile,
                    name,
                    placementOrder,
                    profileImage: getImageUrlFromStrapi(profileImage),
                    profileImageMobile:
                      getImageUrlFromStrapi(profileImageMobile),
                  }
                }
              })
              .filter((item: any) => item)
              .sort((a: any, b: any) => a.placementOrder - b.placementOrder)
            dispatch({
              type: ActionTypes.WHO_WE_ARE_LEADER_LIST,
              payload: {
                whoWeAre: {
                  ...whoWeAre,
                  leaders: {
                    title: leadersTitle,
                    description: leadersDescription,
                    leadersList,
                  },
                },
              },
            })
          }
          return
        } else {
          const {
            home: { whoWeAre },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.WHO_WE_ARE_LEADER_LIST,
            payload: {
              whoWeAre: { ...whoWeAre, leaders: new WhoWeAreLeaders() },
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { whoWeAre },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.WHO_WE_ARE_LEADER_LIST,
          payload: {
            whoWeAre: { ...whoWeAre, leaders: new WhoWeAreLeaders() },
          },
        })
      }
    )
  }
}

//get all categories and blogs
const getBlogs = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getCategoryBlogs
      : endpoints.strapi.getCategoryBlogs
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          let blogs: Array<BlogsCategory> = []
          const formatedBlogs = apiRespone.data.map((item: any) => {
            if (item.attributes.publishedAt) {
              const {
                id,
                attributes: { categoryName, blogs },
              } = item

              let allblogs: Array<Blogs> = []
              blogs.data.map((blogItem: any) => {
                if (blogItem.attributes.publishedAt) {
                  const {
                    id,
                    attributes: {
                      title,
                      publishDate,
                      blogIntroduction,
                      readTime,
                      matterBlogImageWeb,
                      matterBlogImageApp,
                    },
                  } = blogItem

                  const { url } = matterBlogImageWeb.data.attributes
                  const matterBlogThumbNailImageWeb =
                    matterBlogImageWeb.data.attributes.formats.thumbnail.url
                  const urlAppImg = matterBlogImageApp.data.attributes.url
                  const matterBlogThumbNailImageApp =
                    matterBlogImageApp.data.attributes.formats.thumbnail.url

                  let blog = new Blogs()

                  blog.id = id
                  blog.title = title
                  blog.publishDate = publishDate
                  blog.blogIntroduction = blogIntroduction
                  blog.readTime = readTime
                  blog.matterBlogImageWeb = url
                  blog.matterBlogImageApp = urlAppImg
                  blog.matterBlogThumbNailImageWeb = matterBlogThumbNailImageWeb
                  blog.matterBlogThumbNailImageApp = matterBlogThumbNailImageApp
                  allblogs.push(blog)
                }
              })
              return { id, categoryName, allblogs }
            }
          })

          blogs = formatedBlogs

          dispatch({
            type: ActionTypes.BLOGS,
            payload: {
              blogsCategories: blogs,
            },
          })

          return
        } else {
          const {
            home: { blogsCategories },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.BLOGS,
            payload: {
              blogsCategories: blogsCategories,
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { blogsCategories },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.BLOGS,
          payload: {
            blogsCategories: blogsCategories,
          },
        })
      }
    )
  }
}

//get full detailed blog
const getFullBlogs = (id: number) => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getFullBlogs(id)
      : endpoints.strapi.getFullBlogs(id)
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          const blogDetails = new Blogs()
          if (apiRespone.data.attributes) {
            const {
              title,
              publishDate,
              readTime,
              blogIntroduction,
              matterBlogImageWeb,
              blogImageDescription,
              blogStream1,
              blogStream2,
              blogExtraPictures,
              extraPicturesDescription,
            } = apiRespone.data.attributes

            blogDetails.title = title
            blogDetails.publishDate = publishDate
            blogDetails.readTime = readTime
            blogDetails.blogIntroduction = blogIntroduction
            const { url } = matterBlogImageWeb.data.attributes
            blogDetails.matterBlogImageWeb = url
            const matterBlogThumbNailImageWeb =
              matterBlogImageWeb.data.attributes.formats.thumbnail.url
            blogDetails.matterBlogThumbNailImageWeb =
              matterBlogThumbNailImageWeb
            blogDetails.blogImageDescription = blogImageDescription
            blogDetails.blogStream1 = blogStream1
            blogDetails.blogStream2 = blogStream2
            blogDetails.blogExtraPictures = blogExtraPictures.data.map(
              (item: any) => {
                const { url } = item.attributes
                return url
              }
            )
            blogDetails.extraPicturesDescription = extraPicturesDescription
          }

          dispatch({
            type: ActionTypes.BLOG_DETAILS,
            payload: {
              blogDetails: blogDetails,
            },
          })
          return
        } else {
          const {
            home: { blogDetails },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.BLOG_DETAILS,
            payload: {
              blogDetails: blogDetails,
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { blogDetails },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.BLOG_DETAILS,
          payload: {
            blogDetails: blogDetails,
          },
        })
      }
    )
  }
}

const getJoinWaitlist = (success: Function, fail: Function) => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.joinWaitlist
      : endpoints.strapi.joinWaitlist

    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data && apiRespone?.data?.attributes) {
          const data = apiRespone?.data?.attributes
          const formatedTC = {
            title: data.title,
            introduction: data.introduction,
            listContent: [
              { title: data.definationTitle, description: data.definitions },
              {
                title: data.registrationAndWaitlistProcedureTitle,
                description: data.registrationAndWaitlistProcedure,
              },
              {
                title: data.generalTermsAndConditionsTitle,
                description: data.generalTermsAndConditions,
              },
              { title: data.governingLawTitle, description: data.governingLaw },
              { title: data.arbitrationTitle, description: data.arbitration },
              { title: data.contactUsTitle, description: data.contactUs },
            ].filter((item) => item.title),
          }
          dispatch({
            type: ActionTypes.TERM_CONDITION_JOIN_WAITLIST,
            payload: { joinWaitlistTC: formatedTC },
          })
          return
        } else {
        }
      },
      (error: any) => {}
    )
    // var requestOptions = {
    //   method: 'GET',
    //   redirect: 'follow'
    // };
    // fetch("http://4.188.238.31:1337/api/t-and-c-join-waitlist?populate=*", requestOptions)
    //   .then(response => response.json())
    //   .then(result => {
    //     if (result?.data?.attributes) {
    //       const data = result?.data?.attributes
    //       const formatedTC = {
    //         title: data.title,
    //         introduction: data.introduction,
    //         listContent: [
    //           { title: data.definationTitle, description: data.definitions },
    //           {
    //             title: data.registrationAndWaitlistProcedureTitle,
    //             description: data.registrationAndWaitlistProcedure,
    //           },
    //           {
    //             title: data.generalTermsAndConditionsTitle,
    //             description: data.generalTermsAndConditions,
    //           },
    //           { title: data.governingLawTitle, description: data.governingLaw },
    //           { title: data.arbitrationTitle, description: data.arbitration },
    //           { title: data.contactUsTitle, description: data.contactUs },
    //         ].filter((item) => item.title),
    //       }
    //       dispatch({type : ActionTypes.TERM_CONDITION_JOIN_WAITLIST, payload : {joinWaitlistTC : formatedTC} })
    //     }
    //   })
    //   .catch(error => {

    //   } );
  }
}


const getOnlinePreBooking = () => {
  return (dispatch: Function) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.onlinePreBook
      : endpoints.strapi.onlinePreBook

    //const apiEndpoint = endpoints.strapi.onlinePreBook; // Using the single endpoint for online prebooking

    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiResponse: any) => {
        if (apiResponse && apiResponse.data && apiResponse?.data?.attributes) {
          const data = apiResponse?.data?.attributes;
          const formattedTC = {
            title: data.title,
            introduction: data.introduction,
            listContent: [
              { title: data.definitionsTitle, description: data.definitions },
              { title: data.prebookingTitle, description: data.prebooking },
              { title: data.priceTitle, description: data.price },
              { title: data.modificationsTitle, description: data.modifications },
              { title: data.deliveryTitle, description: data.delivery },
              { title: data.cancellationTitle, description: data.cancellation },
              { title: data.insuranceTitle, description: data.insurance },
              { title: data.financeTitle, description: data.finance },
              { title: data.general_T_and_C_Title, description: data.general_T_and_C },
              { title: data.governingLawTitle, description: data.governingLaw },
              { title: data.arbitrationsTitle, description: data.arbitrations },
              { title: data.contactUsTitle, description: data.contactUs },
            ].filter((item) => item.title), // Filtering out any empty titles
          };

          dispatch({
            type: ActionTypes.TERM_CONDITION_ONLINE_PREBOOK,
            payload: { OnlinePreBookTC: formattedTC },
          });
        } else {
          dispatch({
            type: ActionTypes.TERM_CONDITION_ONLINE_PREBOOK,
            payload: { OnlinePreBookTC: { title: '', introduction: '', listContent: [] } },
          });
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.TERM_CONDITION_ONLINE_PREBOOK,
          payload: { OnlinePreBookTC: { title: '', introduction: '', listContent: [] } },
        });
      }
    );
  };
};


export default {
  resetFAQ,
  getAppConfig,
  getCategoryFAQs,
  updateFAQDetails,
  getLocationList,
  getCancelReasons,
  getMasterChildFAQ,
  getBannerStrapiApi,
  getFAQAppliedSearch,
  getContactUsStrapiApi,
  getModifyLocationList,
  getFaqCategoriesStrapiApi,
  getInnovationHubDetails,
  getWhoWeAreAchivements,
  getWhoWeAreEvents,
  getWhoWeAreDetails,
  getWhoWeAreLeaders,
  getBlogs,
  getFullBlogs,
  getJoinWaitlist,
  getHomePageDetails,
  getOnlinePreBooking,
}
